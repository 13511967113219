import React from "react";

class Loader extends React.Component {
  render() {
    return (
      <div className="loader">
        <div className="loader-inner">
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
        </div>
      </div>
    );
  }
}

export default Loader;
