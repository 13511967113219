import React, { Component } from "react";
import { ReactComponent as CaretRight } from "../assets/images/icons/caret-right.svg";
import { ReactComponent as ArrowRight } from "../assets/images/icons/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../assets/images/icons/arrow-left.svg";
import sliderData from "../data/slider";
import Slider from "react-slick";

class HeroSection extends Component {
  constructor(props) {
    super(props);
    this.sliderRef = React.createRef();
    this.state = {
      activeSlide: 0
    };
  }

  goto = index => {
    this.sliderRef.current.slickGoTo(index);
    this.setState({ activeSlide: index });
  };

  next = () => {
    this.sliderRef.current.slickNext();
    this.setState(prevState => {
      if (prevState.activeSlide < sliderData.length - 1) {
        return { activeSlide: prevState.activeSlide + 1 };
      }
    });
  };

  previous = () => {
    this.sliderRef.current.slickPrev();
    this.setState(prevState => {
      if (prevState.activeSlide > 0) {
        return { activeSlide: prevState.activeSlide - 1 };
      }
    });
  };

  render() {
    const settings = {
      arrows: false,
      autoplay: true,
      afterChange: index => {
        this.goto(index);
      },
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (
      <section className="section section-hero">
        <div className="hero">
          <Slider ref={this.sliderRef} {...settings}>
            {sliderData.map((heroImage, index) => (
              <div key={index} className="hero__item">
                <div className="hero__image" style={{ backgroundImage: `url(${heroImage})` }} />
              </div>
            ))}
          </Slider>

          <div className="hero--overlay" />

          <div className="hero-content">
            <div className="container">
              <div className="hero__row">
                <div className="introduction">
                  <h1 className="heading-primary">
                    Committed to <br /> Quality.
                  </h1>
                  <button className="btn btn-white hero-button" onClick={() => this.props.toggleModal()}>
                    Watch <CaretRight height="15" className="hero-button--icon" />
                  </button>
                </div>

                <div className="hero__controls">
                  <div className="hero__controls--top">
                    <ul className="hero__nav">
                      {sliderData.map((heroImage, x) => (
                        <li
                          key={x}
                          className={`hero__nav-item ${this.state.activeSlide === x ? "active" : ""}`}
                          onClick={() => this.goto(x)}
                        />
                      ))}
                    </ul>
                  </div>

                  <div className="hero__controls--bottom">
                    <ArrowLeft className="hero__arrow" onClick={this.previous} />
                    <ul className="hero__dots">
                      {sliderData.map((heroImage, y) => (
                        <li
                          key={y}
                          className={`hero__dot ${this.state.activeSlide === y ? "active" : ""}`}
                          onClick={() => this.goto(y)}
                        />
                      ))}
                    </ul>
                    <ArrowRight className="hero__arrow" onClick={this.next} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-description-block">
          <div className="paragraph hero-description">
            Summit Healthcare Pharmaceuticals has been operational since 1996, providing high quality Ophthalmic
            equipment, pharmaceutical drugs and pharmaceutical products
          </div>
        </div>
      </section>
    );
  }
}

export default HeroSection;
