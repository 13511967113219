import React, { Component } from "react";
import { ReactComponent as Zeiss } from "../assets/images/icons/zeiss.svg";

class AboutSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section id="about" className="section section-about">
        <div className="container">
          <div className="partner">
            <div className="paaragraph partner-text">In partnership with</div>
            <Zeiss className="partner-image" />
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="about-main">
                <p className="paragraph big">
                  Our business covers the distribution of ophthalmic pharmaceutical products, equipment and devices and
                  a wide range of consumer eye care products for the treatment of various forms of eye diseases and
                  disorders.
                </p>
                <p className="paragraph big">
                  Summit Healthcare Pharmaceuticals Ltd is committed to quality products and services to its clients
                  thereby promoting a healthy clientele and making quality healthcare the norm in Africa
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-main">
                <p className="paragraph big">
                  In the last few years, Summit Healthcare Pharmaceuticals Ltd has also strengthened its focus on the
                  servicing and maintenance of ophthalmic and optometric equipment and services through a significant
                  investment in the training and certification of qualified local talent. The company is the authorized
                  distributor and service providers of Zeiss Ophthalmic equipment in Nigeria.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default AboutSection;
