import React from "react";
import { Modal, ModalBody } from "reactstrap";
import close from "../assets/images/icons/close.svg";
import zesisVideo from "../assets/video/zeiss-essential-line-video.mp4";

class Video extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalSize: "xl",
      backdrop: true
    };
  }

  render() {
    return (
      <Modal
        isOpen={this.props.modal}
        toggle={this.props.toggleModal}
        className="general-modal contact-modal"
        size={this.state.modalSize}
      >
        <ModalBody>
          <div className="video-modal">
            <div className="container-fluid m-0 p-0">
              <img src={close} alt="close" className="close-icon" title="Close" onClick={this.props.toggleModal} />

              <video width="100%" height="100%" src={zesisVideo} type="video/mp4" className="video" autoPlay>
                <p>Your browser does not support the video tag.</p>
              </video>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default Video;
