import React, { Component } from "react";
import PropTypes from "prop-types";
import maintenance from "../assets/images/services/maintenance.png";
import pharmaceutical from "../assets/images/services/pharmaceutical.png";
import ophthalmic from "../assets/images/services/ophthalmic.png";

class ServicesSection extends Component {
  render() {
    return (
      <section id="services" className="section section-services">
        <div className="container-fluid p-0">
          <div className="row services">
            <div className="col-md-4 p-0">
              <div className="service" title="Click for more details..">
                <div className="service__image" style={{ backgroundImage: `url(${ophthalmic})` }} />
                <div className="service__footer">
                  <h4 className="service__title">Ophthalmic Services</h4>
                  <button className="btn btn-outline" onClick={() => this.props.toggleServiceModal("ophthalmic")}>
                    Learn more
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-0">
              <div className="service" title="Click for more details..">
                <div className="service__image" style={{ backgroundImage: `url(${maintenance})` }} />
                <div className="service__footer">
                  <h4 className="service__title">Maintenance & Installation</h4>
                  <button className="btn btn-outline" onClick={() => this.props.toggleServiceModal("maintenance")}>
                    Learn more
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-0">
              <div className="service" title="Click for more details..">
                <div className="service__image" style={{ backgroundImage: `url(${pharmaceutical})` }} />
                <div className="service__footer">
                  <h4 className="service__title">Pharmaceutical Services</h4>
                  <button className="btn btn-outline" onClick={() => this.props.toggleServiceModal("pharmaceutical")}>
                    Learn more
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ServicesSection.propTypes = {
  toggleServiceModal: PropTypes.func
};

export default ServicesSection;
