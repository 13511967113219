import React, { Component } from "react";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem } from "reactstrap";
import logo from "../assets/images/logo.svg";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navCollapsIsOpen: false
    };
    this.toggleNavCollapse = this.toggleNavCollapse.bind(this);
    this.toggleContactModal = this.toggleContactModal.bind(this);
  }

  toggleNavCollapse() {
    this.setState({
      navCollapsIsOpen: !this.state.navCollapsIsOpen
    });
  }

  toggleContactModal() {
    this.setState({
      contactModal: !this.state.contactModal
    });
  }

  render() {
    return (
      <header className="section section-header">
        <Navbar expand="md" className="navigation-bar">
          <div className="container">
            <NavbarBrand href="/">
              <img src={logo} alt="Logo" className="logo" />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavCollapse}>
              <i className="fas fa-bars" />
            </NavbarToggler>
            <Collapse isOpen={this.state.navCollapsIsOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <a className="nav-link" href="/">
                    Home
                  </a>
                </NavItem>
                <NavItem>
                  <a className="nav-link" href="#services" onClick={() => this.props.toggleServiceModal("")}>
                    Our Services
                  </a>
                </NavItem>
                <NavItem>
                  <a className="btn btn-primary nav-link--button" href="/#" onClick={() => this.props.toggleModal()}>
                    Get in touch
                  </a>
                </NavItem>
              </Nav>
            </Collapse>
          </div>
        </Navbar>
      </header>
    );
  }
}

export default Header;
