import React, { Component } from "react";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <footer id="footer" className="section section-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="about">
                <h4 className="header">About</h4>
                <p className="paragraph">
                  Summit Healthcare <br />
                  Pharmaceuticals Ltd – <br />
                  committed to quality.
                </p>
                <p className="paragraph small copyright">
                  2019 Summit Healthcare <br />
                  Pharmaceuticals Ltd.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="site-sections">
                <h4 className="header">Quick Links</h4>
                <div className="quick-links">
                  <a href="#about" className="link">
                    About Us
                  </a>
                  <a href="#services" className="link">
                    Our Services
                  </a>
                  <a href="#products" className="link">
                    Products
                  </a>
                  <a href="#footer" className="link" onClick={() => this.props.toggleModal()}>
                    Get in touch
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact">
                <h4 className="header">Contact</h4>
                <div className="contact-items">
                  <div className="item">
                    <i className="fas fa-map-marker-alt icon" />
                    <div className="label">
                      <p className="paragraph">
                        27b Isaac John Street Ikeja,
                        <br />
                        GRA Lagos.
                      </p>
                    </div>
                  </div>
                  <div className="item">
                    <i className="fas fa-envelope icon" />
                    <div className="label">
                      <p className="paragraph">
                        <a href="mailto:sales@summitpharma.ng" className="link">
                          sales@summitpharma.ng
                        </a>
                        <br />
                        <a href="mailto:technicalservices@summitpharma.ng" className="link">
                          technicalservices@summitpharma.ng
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="item">
                    <i className="fas fa-phone icon" />
                    <div className="label">
                      <p className="paragraph">
                        Sales :{" "}
                        <a href="tel:(081) 508 844 93" className="link">
                          +234 (081) 508 844 93
                        </a>
                        <br />
                        Technical :{" "}
                        <a href="tel:(081) 508 844 96" className="link">
                          +234 (081) 508 844 96
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
