import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import PropTypes from "prop-types";

export const Select = props => (
  <FormGroup className={`form__group ${props.className}`}>
    <Label className="form__label">{props.label}</Label>
    <Input
      className="form__input form__select"
      disabled={props.isDisabled}
      name={props.id}
      type="select"
      value={props.value}
      onChange={props.onChange}
    >
      <option value="" disabled defaultValue hidden>
        {props.placeholder}
      </option>

      {props.children}
    </Input>
  </FormGroup>
);

Select.defaultProps = {
  className: "",
  options: [],
  placeholder: "",
  value: null
};

Select.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node,
  placeholder: PropTypes.string,
  value: PropTypes.string
};
