import React from "react";
import { Form, Input, Modal, ModalBody } from "reactstrap";
import close from "../assets/images/icons/close-black.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Loader";

const _isStrEmpty = str => {
  return !str || 0 === str.length;
};

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backdrop: true,
      email: "",
      error: true,
      isLoading: false,
      message: "",
      modalSize: "xl",
      name: ""
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.handleForm = this.handleForm.bind(this);

    toast.configure({
      autoClose: 8000,
      draggable: false
    });
  }

  onInputChange = (inputField, value) => {
    const data = {};
    data[inputField] = value;
    this.setState(data);
  };

  notify = message => toast(message);

  handleForm = e => {
    e.preventDefault();
    let { email, error, message, name } = this.state;

    if (_isStrEmpty(email) || _isStrEmpty(message) || _isStrEmpty(name)) {
      return this.notify("Please fill in the fields");
    }

    this.setState({ isLoading: true });

    const formData = new FormData();
    formData.append("email", email);
    formData.append("message", message);
    formData.append("name", name);

    const instance = axios.create({
      baseURL: "https://usebasin.com/f/013946f41a85.json",
      timeout: 5000,
      headers: {
        "Accept-Version": 1,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json; charset=utf-8"
      }
    });

    instance
      .post("/", formData)
      .then(response => {
        let statusMessage = "";
        if (response && response.status === 200) {
          error = false;
          statusMessage = "Message sent successfully";
        } else {
          error = true;
          statusMessage = "Message failed, internal server error";
        }
        this.setState({ email: "", error: error, isLoading: false, message: "", name: "" });
        this.notify(statusMessage);
      })
      .catch(error => {
        this.setState({ error: true, isLoading: false });
        this.notify("Message failed, network Error");
      });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.modal}
        toggle={this.props.toggleModal}
        className="general-modal contact-modal"
        size={this.state.modalSize}
      >
        <ModalBody>
          <div className="contact">
            <div className="container-fluid">
              <img src={close} alt="close" className="close-icon" title="Close" onClick={this.props.toggleModal} />

              <div className="row">
                <div className="contact__col contact__col--left">
                  <h4 className="contact__title">For more information on our products and services, contact us</h4>
                  <ToastContainer className={this.state.error ? "toastify--error" : "toastify--success"} />
                  <div className="contact-items">
                    <div className="col-sm-12 col-lg-6 col-xl-12">
                      <div className="item">
                        <i className="fas fa-map-marker-alt icon" />
                        <div className="label">
                          <p className="paragraph">
                            27b Isaac John Street Ikeja,
                            <br />
                            GRA Lagos.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-lg-6 col-xl-12">
                      <div className="item">
                        <i className="fas fa-envelope icon" />
                        <div className="label">
                          <p className="paragraph">
                            <a href="mailto:sales@summitpharma.ng" className="link">
                              sales@summitpharma.ng
                            </a>
                            <br />
                            <a href="mailto:technicalservices@summitpharma.ng" className="link">
                              technicalservices@summitpharma.ng
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-lg-6 col-xl-12">
                      <div className="item">
                        <i className="fas fa-phone icon" />
                        <div className="label">
                          <p className="paragraph">
                            Sales :{" "}
                            <a href="tel:(081) 508 844 93" className="link">
                              +234 (081) 508 844 93
                            </a>
                            <br />
                            Technical :{" "}
                            <a href="tel:(081) 508 844 96" className="link">
                              +234 (081) 508 844 96
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contact__col contact__col--right">
                  <div className="form-section">
                    <Form>
                      <div className="form-group">
                        <Input
                          type="text"
                          value={this.state.name}
                          onChange={e => this.onInputChange("name", e.target.value)}
                          className="text-box"
                          name="name"
                          id="name"
                          placeholder="Name"
                        />
                      </div>

                      <div className="form-group">
                        <Input
                          type="email"
                          value={this.state.email}
                          onChange={e => this.onInputChange("email", e.target.value)}
                          className="text-box"
                          name="email"
                          id="email"
                          placeholder="Email Address"
                        />
                      </div>

                      <div className="form-group">
                        <Input
                          type="textarea"
                          className="text-area"
                          value={this.state.message}
                          onChange={e => this.onInputChange("message", e.target.value)}
                          name="message"
                          id="message"
                          placeholder="Message"
                        />
                      </div>
                      <button className="btn btn--full-width contact-button" onClick={e => this.handleForm(e)}>
                        {this.state.isLoading ? <Loader /> : "send message"}
                      </button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default ContactForm;
