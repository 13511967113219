import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";
import close from "../assets/images/icons/close.svg";
import servicesData from "../data/services";
import { Select } from "./Select";

const _filterArray = (array, property, selected) => {
  return array.filter(item => item[property] === selected)[0];
};

const _isObjectEmpty = obj => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }

  return true;
};

const _isArrayEmpty = arr => {
  return !arr || arr.length === 0;
};

class ServiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: {},
      selectedService: "",
      selectedCategory: "",
      service: {}
    };

    this._handleServiceChange = this._handleServiceChange.bind(this);
    this._handleCategoryChange = this._handleCategoryChange.bind(this);
  }

  componentDidMount() {
    this._updatePageView();
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.selectedService !== this.props.selectedService) {
      this._updatePageView();
    }
  }

  _updatePageView = () => {
    let { category, selectedService, service } = this.state;

    selectedService = this.props.selectedService || "ophthalmic";

    service = _filterArray(servicesData, "name", selectedService);

    !_isObjectEmpty(service) ? (category = service.categories[0]) : (category = {});

    this.setState({
      category: category,
      selectedCategory: !_isObjectEmpty(category) ? category.name : "",
      selectedService: selectedService || "",
      service: service
    });
  };

  _handleServiceChange = selectedService => {
    const service = _filterArray(servicesData, "name", selectedService);
    let category = {};
    !_isObjectEmpty(service) ? (category = service.categories[0]) : (category = {});

    this.setState({
      category: category,
      selectedCategory: !_isObjectEmpty(category) ? category.name : "",
      selectedService: service.name,
      service: service
    });
  };

  _handleCategoryChange = selectedCategory => {
    let { category, service } = this.state;
    console.log(category);

    console.log(selectedCategory);
    const newCategory = _filterArray(service.categories, "name", selectedCategory);
    console.log(newCategory);
    this.setState({ category: newCategory, selectedCategory: selectedCategory });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.serviceModal}
        toggle={this.props.toggleServiceModal}
        className="modal--full-screen general-modal"
        size={this.props.modalSize}
      >
        <ModalBody>
          <div className="modal--border">
            <div className="container">
              <div className="row justify-content-between">
                <div className="service__cta">
                  <div className="service__cta--text">
                    For more information on our <br /> products and services, contact us
                  </div>
                  <a
                    className="btn btn-primary service__cta--button"
                    href="/#"
                    onClick={() => this.props.toggleContactModal()}
                  >
                    Get in touch
                  </a>
                </div>
                <img
                  src={close}
                  alt="close"
                  className="close-icon"
                  title="Close"
                  onClick={() => this.props.toggleServiceModal("")}
                />
              </div>
            </div>
          </div>

          <div className="modal__header">
            <div className="container">
              <div className="title-section">
                <h4 className="title">Explore our items</h4>
                <div className="paragraph title--sub">{this.state.service.subtitle}</div>
              </div>
            </div>
          </div>

          <div className="modal__body">
            <div className="container">
              <div className="service__select-block">
                <div className="service__select-inner">
                  <Select
                    label="Select a Service"
                    className="service__select"
                    placeholder="Services"
                    onChange={e => this._handleServiceChange(e.target.value)}
                    value={this.state.selectedService}
                  >
                    {servicesData.map((item, index) => (
                      <option value={item.name} key={index}>
                        {item.name}
                      </option>
                    ))}
                  </Select>

                  {!_isArrayEmpty(this.state.service.categories) ? <div className="form__divider" /> : null}

                  {!_isArrayEmpty(this.state.service.categories) ? (
                    <Select
                      label="Select a Category"
                      className="service__select"
                      placeholder="Service Categories"
                      onChange={e => this._handleCategoryChange(e.target.value)}
                      value={this.state.selectedCategory}
                    >
                      {this.state.service.categories.map((item, index) => (
                        <option value={item.name} key={index}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                  ) : null}
                </div>
              </div>

              <div className="service__content">
                <div className="title-section">
                  <h4 className="heading-secondary">{this.state.service.title}</h4>
                </div>

                {!_isArrayEmpty(this.state.service.description) ? (
                  <div className="service__description-block">
                    {this.state.service.description.map((desc, a) => (
                      <p className="paragraph" key={a}>
                        {desc}
                      </p>
                    ))}
                  </div>
                ) : null}

                {!_isArrayEmpty(this.state.service.categories) || !_isArrayEmpty(this.state.service.pictures) ? (
                  <div className="service__pictures">
                    <div className="container">
                      <div className="row">
                        {!_isObjectEmpty(this.state.category)
                          ? this.state.category.pictures.map((cat, b) => (
                              <div className="col-md-4" key={b}>
                                <div className="service__item">
                                  <div className="service__picture" style={{ backgroundImage: `url(${cat.image})` }} />
                                  <p className="paragraph bold">{cat.title}</p>
                                </div>
                              </div>
                            ))
                          : null}

                        {!_isArrayEmpty(this.state.service.pictures)
                          ? this.state.service.pictures.map((pic, c) => (
                              <div className="col-md-4" key={c}>
                                <div className="service__item">
                                  <div className="service__picture" style={{ backgroundImage: `url(${pic.image})` }} />
                                  <p className="paragraph bold">{pic.title}</p>
                                </div>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

ServiceModal.propTypes = {
  className: PropTypes.string,
  modalSize: PropTypes.string,
  serviceModal: PropTypes.bool,
  selectedService: PropTypes.string,
  toggleServiceModal: PropTypes.func,
  toggleContactModal: PropTypes.func
};

ServiceModal.defaultProps = {
  selectedService: "ophthalmic"
};

export default ServiceModal;
