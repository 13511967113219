import SL115Classic from "../assets/images/products/ophthalmic/basic-diagnostics/SL115classic.jpg";
import SL120 from "../assets/images/products/ophthalmic/basic-diagnostics/SL120.jpg";
import SL130 from "../assets/images/products/ophthalmic/basic-diagnostics/SL130.jpg";
import SL220 from "../assets/images/products/ophthalmic/basic-diagnostics/SL220.jpg";
import SLImagingModule from "../assets/images/products/ophthalmic/basic-diagnostics/SLImagingmodule.jpg";
import VisuConnect500 from "../assets/images/products/ophthalmic/basic-diagnostics/visuconnect500.jpg";
import VisuLens500 from "../assets/images/products/ophthalmic/basic-diagnostics/Visulens500.jpg";
import VisuPhor from "../assets/images/products/ophthalmic/basic-diagnostics/visuphor500.jpg";
import VisuPlan500 from "../assets/images/products/ophthalmic/basic-diagnostics/Visuplan500.jpg";
import VisuRef100 from "../assets/images/products/ophthalmic/basic-diagnostics/VISUREF100.jpg";
import Visuscout100 from "../assets/images/products/ophthalmic/basic-diagnostics/VISUSCOUT.jpg";
import VisuScreen100500 from "../assets/images/products/ophthalmic/basic-diagnostics/visuscreen100-500.jpg";

import AngioplexOCT from "../assets/images/products/ophthalmic/refractive-surgery/AngioplexOCT.jpg";
import CRSMaster from "../assets/images/products/ophthalmic/refractive-surgery/CRS_Master_Produkt_7303_ret_52625_1Previewlarge.jpg";
import IOLmaster500 from "../assets/images/products/ophthalmic/refractive-surgery/IOL_Master_500.jpg";
import IOLmaster700 from "../assets/images/products/ophthalmic/refractive-surgery/IOLMaster_700_28.07.2014_C82421_1E_102003_0Previewlarge.jpg";
import Mel9 from "../assets/images/products/ophthalmic/refractive-surgery/mel90.jpg";
import visucam524 from "../assets/images/products/ophthalmic/refractive-surgery/visucam524.jpg";
import VisulasYagIIICombi from "../assets/images/products/ophthalmic/refractive-surgery/VisulasYAG3_Combi.jpg";
import VisulasTrion from "../assets/images/products/ophthalmic/refractive-surgery/visulas-trion_mp_800_800_1_1470396041.jpg";
import VisulasYagIII from "../assets/images/products/ophthalmic/refractive-surgery/visulas-yagiii.jpg";
import Visulas532s from "../assets/images/products/ophthalmic/refractive-surgery/visulas532s.jpg";
import Visumax from "../assets/images/products/ophthalmic/refractive-surgery/visumax.jpg";

import callistoeye from "../assets/images/products/ophthalmic/cataract-and-retina-surgery/callistoeye.jpg";
import OPMI_Lumera_I from "../assets/images/products/ophthalmic/cataract-and-retina-surgery/OPMI-Lumera-i.jpg";
import OPMI_Lumera_T from "../assets/images/products/ophthalmic/cataract-and-retina-surgery/OPMI-Lumera-T.jpg";
import OPMI_LUMERA_300_advanced from "../assets/images/products/ophthalmic/cataract-and-retina-surgery/OPMI_LUMERA_300_advanced.jpg";
import OPMI_LUMERA_300_basic from "../assets/images/products/ophthalmic/cataract-and-retina-surgery/OPMI_LUMERA_300_basic_with_rays.jpg";
import opmilumera700 from "../assets/images/products/ophthalmic/cataract-and-retina-surgery/opmilumera700.jpg";
import resight500 from "../assets/images/products/ophthalmic/cataract-and-retina-surgery/resight500.jpg";
import Trio610_ from "../assets/images/products/ophthalmic/cataract-and-retina-surgery/Trio610_.jpg";
import trio610_hand from "../assets/images/products/ophthalmic/cataract-and-retina-surgery/trio610_hand.jpg";
import visalis100 from "../assets/images/products/ophthalmic/cataract-and-retina-surgery/visalis100.jpg";
import visu140 from "../assets/images/products/ophthalmic/cataract-and-retina-surgery/visu140.jpg";
import visu160 from "../assets/images/products/ophthalmic/cataract-and-retina-surgery/visu160.jpg";
import ZEISS_Visalis500_1007 from "../assets/images/products/ophthalmic/cataract-and-retina-surgery/ZEISS_Visalis500_1007 2819_bearb1_cmyk_1E.jpg";
import ZEISS_Visalis500_family from "../assets/images/products/ophthalmic/cataract-and-retina-surgery/ZEISS_Visalis500_family.jpg";

import Clarrus500 from "../assets/images/products/ophthalmic/glaucoma-and-retina/Clarrus500.PNG";
import HumphreyFieldAnalyzer from "../assets/images/products/ophthalmic/glaucoma-and-retina/HumphreyFieldAnalyzer.PNG";
import humphreymatrix800 from "../assets/images/products/ophthalmic/glaucoma-and-retina/humphreymatrix800.jpg";
import Primus200 from "../assets/images/products/ophthalmic/glaucoma-and-retina/Primus200.jpg";

import DioclosumDiclofenacSodiumOphthalmic from "../assets/images/products/pharmaceutical/DioclosumDiclofenacSodiumOphthalmic.jpg";
import Sumidexa from "../assets/images/products/pharmaceutical/Sumidexa.jpg";
import SumitololTimolol from "../assets/images/products/pharmaceutical/SumitololTimolol.jpg";
import SuproxanCiprofloxacin from "../assets/images/products/pharmaceutical/SuproxanCiprofloxacin.jpg";

const servicesData = [
  {
    name: "ophthalmic",
    categories: [
      {
        name: "basic diagnosis",
        pictures: [
          {
            image: SL115Classic,
            title: "SL 115 Classic"
          },
          {
            image: SL120,
            title: "SL 120"
          },
          {
            image: SL130,
            title: "SL 130"
          },
          {
            image: SL220,
            title: "SL 220"
          },
          {
            image: SLImagingModule,
            title: "SL Imaging Module"
          },
          {
            image: VisuConnect500,
            title: "Visu Connect 500"
          },
          {
            image: VisuLens500,
            title: "VisuLens 500"
          },
          {
            image: VisuPlan500,
            title: "VisuPlan 500"
          },
          {
            image: VisuRef100,
            title: "VisuRef 100"
          },
          {
            image: Visuscout100,
            title: "Visuscout 100"
          },
          {
            image: VisuScreen100500,
            title: "Visu Screen 100"
          },
          {
            image: VisuScreen100500,
            title: "Visu Screen 500"
          },
          {
            image: VisuPhor,
            title: "Visu Phor"
          }
        ]
      },
      {
        name: "refractive surgery",
        pictures: [
          {
            image: AngioplexOCT,
            title: "Angioplex OCT"
          },
          {
            image: visucam524,
            title: "VisuCam 534/224"
          },
          {
            image: Visulas532s,
            title: "Visulas 532s"
          },
          {
            image: VisulasTrion,
            title: "Visulas Trion"
          },
          {
            image: VisulasYagIII,
            title: "Visulas Yag III"
          },
          {
            image: VisulasYagIIICombi,
            title: "Visulas Yag III Combi"
          },
          {
            image: CRSMaster,
            title: "CRS Master"
          },
          {
            image: Visumax,
            title: "Visumax"
          },
          {
            image: Mel9,
            title: "Mel 9"
          },
          {
            image: IOLmaster500,
            title: "IOL master 500"
          },
          {
            image: IOLmaster700,
            title: "IOL master 700"
          }
        ]
      },
      {
        name: "Cataract and Retina Surgery",
        pictures: [
          {
            image: OPMI_LUMERA_300_advanced,
            title: "OPMI Lumera 300 Advanced"
          },
          {
            image: OPMI_LUMERA_300_basic,
            title: "OPMI Lumera 300 with rays"
          },
          {
            image: opmilumera700,
            title: "OPMI Lumera 700"
          },
          {
            image: OPMI_Lumera_I,
            title: "OPMI Lumera i"
          },
          {
            image: OPMI_Lumera_T,
            title: "OPMI Lumera T"
          },
          {
            image: resight500,
            title: "Resight 500/700"
          },
          {
            image: ZEISS_Visalis500_family,
            title: "Visalis 500 Family"
          },
          {
            image: visalis100,
            title: "Visalis 100"
          },
          {
            image: ZEISS_Visalis500_1007,
            title: "Visuref 100"
          },
          {
            image: Trio610_,
            title: "Trio 610"
          },
          {
            image: trio610_hand,
            title: "Trio 610 hand"
          },
          {
            image: callistoeye,
            title: "Callisto Eye"
          },
          {
            image: visu140,
            title: "Visu 140"
          },
          {
            image: visu160,
            title: "Visu 160"
          }
        ]
      },
      {
        name: "Glaucoma and Retina",
        pictures: [
          {
            image: Primus200,
            title: "Primus 200"
          },
          {
            image: HumphreyFieldAnalyzer,
            title: "Humphrey Field Analyzer"
          },
          {
            image: humphreymatrix800,
            title: "Humphrey Matrix 800"
          },
          {
            image: Clarrus500,
            title: "Clarus 500"
          }
        ]
      }
    ],
    description: [],
    pictures: [],
    subtitle:
      "We supply cutting-edge technologies and application-oriented solutions for ophthalmology and microsurgery from Zeiss.",
    title: "Ophthalmic Services"
  },
  {
    name: "maintenance",
    categories: [],
    description: [
      "Summit Healthcare Pharmaceuticals Ltd services does not end with the sale of equipment. We ensure that our clients are able have all the installation and service support for them to harness maximum value from their acquisition.",
      "Our dedicated medical technology technical team are equipped and professionally trained to provide installation and after sales support services to our clients. Our Field Service Engineers undergo extensive training and gain necessary certification for every range of device that we supply.",
      "We provide on-demand maintenance services as well as bespoke maintenance service packages under a maintenance service agreement."
    ],
    pictures: [],
    subtitle: "",
    title: "Maintenance & Installation"
  },
  {
    name: "pharmaceutical",
    categories: [],
    description: [],
    pictures: [
      {
        image: DioclosumDiclofenacSodiumOphthalmic,
        title: "Dioclosum Diclofenac Sodium Ophthalmic"
      },
      {
        image: Sumidexa,
        title: "Sumitolol, Timolol Eye Drops"
      },
      {
        image: SumitololTimolol,
        title: "Sumidexa, Dexamethaso ne Eye/Ear Drop"
      },
      {
        image: SuproxanCiprofloxacin,
        title: "Suproxan Ciprofloxacin Eye/Ear Drop"
      }
    ],
    subtitle:
      "Summit Healthcare Pharmaceuticals Ltd has established a niche for itself in the sale and distribution of ophthalmic pharmaceutical products and consumables",
    title: "Pharmaceutical Services"
  }
];

export default servicesData;
