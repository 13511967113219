import React, { Component } from "react";
import Slider from "react-slick";
import SectionHeading from "./SectionHeading";
import products from "../data/data.products";
import shipping from "../assets/images/icons/shipping.svg";
import tag from "../assets/images/icons/tag.svg";
import quality from "../assets/images/icons/quality.svg";

class ProductSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      nav3: null,
      activeSlide: 0
    };
    this.handleSlider = this.handleSlider.bind(this);
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
      nav3: this.slider3
    });
  }

  handleSlider(index) {
    this.setState({ activeSlide: index }, () => this.slider3.slickGoTo(index));
  }

  render() {
    return (
      <section id="products" className="section section-products">
        <div className="container">
          <SectionHeading text="Featured Products" />
          <div className="row">
            <div className="col-md-5 mb-5">
              <Slider
                asNavFor={this.state.nav2}
                ref={slider => (this.slider1 = slider)}
                swipeToSlide={false}
                arrows={false}
                fade={true}
                className="product-preview__carousel"
              >
                {products.map((product, index) => (
                  <div className="product-preview__carousel--item" key={index}>
                    <div
                      style={{ backgroundImage: `url(${product.image})` }}
                      className="product-preview__carousel--image"
                    />
                  </div>
                ))}
              </Slider>

              <Slider
                asNavFor={this.state.nav1}
                ref={slider => (this.slider2 = slider)}
                slidesToShow={4}
                swipeToSlide={true}
                focusOnSelect={true}
                afterChange={index => this.handleSlider(index)}
                className="product-thumbnail__carousel"
              >
                {products.map((product, index) => (
                  <div className="product-thumbnail__carousel--item" key={index}>
                    <div
                      style={{ backgroundImage: `url(${product.image})` }}
                      className="product-thumbnail__carousel--image"
                    />
                  </div>
                ))}
              </Slider>
            </div>

            <div className="filler" />

            <div className="col-md-6">
              <Slider asNavFor={this.state.nav2} ref={slider => (this.slider3 = slider)} arrows={false} fade={true}>
                {products.map((product, index) => (
                  <div className="product-details" key={index}>
                    <p className="paragraph big bold">{product.name}</p>
                    <p className="paragraph">{product.description}</p>
                    <div className="product-details__features">
                      <div className="product-details__features--item">
                        <img src={shipping} alt="shipping icon" className="icon" />
                        <span className="label">Fast shipping</span>
                      </div>
                      <div className="product-details__features--item">
                        <img src={quality} alt="tag icon" className="icon" />
                        <span className="label">highest quality</span>
                      </div>
                      <div className="product-details__features--item">
                        <img src={tag} alt="machine icon" className="icon" />
                        <span className="label">best prices</span>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ProductSection;
