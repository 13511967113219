import React from "react";
import PropTypes from "prop-types";

const SectionHeading = props => (
  <div className="row">
    <div className="col-md-12">
      <h4 className={`heading-secondary ${props.extraClass}`}>{props.text}</h4>
    </div>
  </div>
);

SectionHeading.defaultProps = {
  extraClass: "",
  text: ""
};

SectionHeading.propTypes = {
  extraClass: PropTypes.string,
  text: PropTypes.string
};

export default SectionHeading;
