import VisuRef100 from "../assets/images/products/ophthalmic/basic-diagnostics/VISUREF100.jpg";
import SL120 from "../assets/images/products/ophthalmic/basic-diagnostics/SL120.jpg";
import SLImagingModule from "../assets/images/products/ophthalmic/basic-diagnostics/SLImagingmodule.jpg";
import AngioplexOCT from "../assets/images/products/ophthalmic/refractive-surgery/AngioplexOCT.jpg";
import HumphreyFieldAnalyzer from "../assets/images/products/ophthalmic/glaucoma-and-retina/HumphreyFieldAnalyzer.PNG";
import IOLmaster500 from "../assets/images/products/ophthalmic/refractive-surgery/IOL_Master_500.jpg";
import IOLmaster700 from "../assets/images/products/ophthalmic/refractive-surgery/IOLMaster_700_28.07.2014_C82421_1E_102003_0Previewlarge.jpg";
import resight500 from "../assets/images/products/ophthalmic/cataract-and-retina-surgery/resight500.jpg";
import Visumax from "../assets/images/products/ophthalmic/refractive-surgery/visumax.jpg";
import DioclosumDiclofenacSodiumOphthalmic from "../assets/images/products/pharmaceutical/DioclosumDiclofenacSodiumOphthalmic.jpg";
import Sumidexa from "../assets/images/products/pharmaceutical/Sumidexa.jpg";
import SumitololTimolol from "../assets/images/products/pharmaceutical/SumitololTimolol.jpg";
import SuproxanCiprofloxacin from "../assets/images/products/pharmaceutical/SuproxanCiprofloxacin.jpg";

const products = [
  {
    description:
      "The VISUREF ® 100 is a straightforward, easy-to-use diagnostic instrument provides highly accurate and reliable data for your daily practice routines.",
    image: VisuRef100,
    name: "Zeiss Visuref 100"
  },
  {
    description:
      "The SL 120 slit lamp offers superb optical transmission with high-resolution, high-contrast images for all types of eye examinations from overview to detail for outstanding optical performance.",
    image: SL120,
    name: "SL 120"
  },
  {
    description:
      "Our slit lamps are made from high-quality materials and built to provide years of reliable service. With each one of them having it’s unique usability and special qualities.",
    image: SLImagingModule,
    name: "Slit Lamp Examination"
  },
  {
    description:
      "CIRRUSTM OCT with AngioPlex​ ​Metrix​TM offers a major step forward in making retinal disease management and treatment planning more efficient and effective. Which provides deeper visualizations, giving you more information in less time to assess whether your patient is stable or progressing and whether it is the right time to refer or intervene.",
    image: AngioplexOCT,
    name: "Angioplex metric"
  },
  {
    description:
      "This equipments ​provides a streamlined and faster workflow with an array of remarkable new features designed to Reduce visual field​, ​Reduce setup time​ with a single trial lens and ​Improve confidence​ in test results.",
    image: HumphreyFieldAnalyzer,
    name: "Humphrey Field Analyzer – HFA3 Visual Field Series"
  },
  {
    description:
      "We offer IOLMaster which is the only optical biometer to feature ​distance-independent telecentric keratometry​. It enables ​robust and repeatable​ measurements and shows excellent agreement with manual keratometry while achieving ​higher precision​.",
    image: IOLmaster500,
    name: "IOL Master (500)"
  },
  {
    description:
      "Our IOLMaster 700 machine allows you to directly measure the posterior corneal surface using ​SWEPT Source OCT​ and Can be used in classic IOL calculation formulas. No need for a second device, third-party software or an online calculator",
    image: IOLmaster700,
    name: "IOL Master (700)"
  },
  {
    description:
      "Using resight 500 will provide you with an excellent visualization of the eye, an excellent ergonomics and integrated assistance functions. Enhancing perception for high performance surgery giving total Comfort and control throughout the day of surgery.",
    image: resight500,
    name: " Resight 500"
  },
  {
    description:
      "Laser Vision Correction procedure portfolios, includes PRK, LASIK and SMILE. Which creates better vision standards for everyone, while also enabling you to provide better choices and the right solution in refractive surgery to any eligible patient.",
    image: Visumax,
    name: "Femtosecond Laser"
  },
  {
    description: "Diclofenac Sodium Ophthalmic Solution 0.1% 5ml",
    image: DioclosumDiclofenacSodiumOphthalmic,
    name: "Diclosum"
  },
  {
    description: "Dexamathasone Eye/Ear Drops 5ml",
    image: Sumidexa,
    name: "Sumidexa"
  },
  {
    description: "Timolol Eye Drop Bp 0.5% w/v 5ml",
    image: SumitololTimolol,
    name: "Sumitolol"
  },
  {
    description: "Tropicamide Eye Drops Bp 5ml",
    image: SuproxanCiprofloxacin,
    name: "Suproxan"
  }
];

export default products;
