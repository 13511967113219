import React, { Component } from "react";
import AboutSection from "./components/AboutSection";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import ProductSection from "./components/ProductSection";
import ServicesSection from "./components/ServicesSection";
import ContactForm from "./components/ContactForm";
import "./App.scss";
// import Testimonial from "./components/Testimonials";
// import testimonialData from "./data/testimonial";
import ServiceModal from "./components/ServicModal";
import Video from "./components/Video";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactModal: false,
      modalSize: "lg",
      serviceModal: false,
      selectedService: "",
      videoModal: false
    };

    this.onNavClick = this.onNavClick.bind(this);
    this.toggleContactModal = this.toggleContactModal.bind(this);
    this.toggleServiceModal = this.toggleServiceModal.bind(this);
    this.toggleVideoModal = this.toggleVideoModal.bind(this);
  }

  onNavClick(item) {
    window.scrollTo(0, this[item].current.offsetTop);
  }

  toggleContactModal() {
    this.setState({
      serviceModal: false,
      contactModal: !this.state.contactModal
    });
  }

  toggleServiceModal(service) {
    this.setState({
      serviceModal: !this.state.serviceModal,
      selectedService: service
    });
  }

  toggleVideoModal() {
    this.setState({
      videoModal: !this.state.videoModal
    });
  }

  render() {
    return (
      <div className="page">
        <Header onNavClick toggleServiceModal={this.toggleServiceModal} toggleModal={this.toggleContactModal} />
        <HeroSection toggleModal={this.toggleVideoModal} />
        <AboutSection />
        <ServicesSection toggleServiceModal={this.toggleServiceModal} />
        <ProductSection />
        {/* <Testimonial data={testimonialData} /> */}
        <Footer toggleModal={this.toggleContactModal} />
        <ContactForm modal={this.state.contactModal} toggleModal={this.toggleContactModal} />
        <ServiceModal
          modalSize={this.state.modalSize}
          selectedService={this.state.selectedService}
          serviceModal={this.state.serviceModal}
          toggleServiceModal={this.toggleServiceModal}
          toggleContactModal={this.toggleContactModal}
        />
        <Video modal={this.state.videoModal} toggleModal={this.toggleVideoModal} />
      </div>
    );
  }
}

export default App;
